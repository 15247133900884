import React, { useState, useEffect, useContext } from "react";
import { ReactReduxContext } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { withStyles } from "@material-ui/core/styles";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import Button from "@material-ui/core/Button";
import styles from "./FindingsPopup.module.css";
import { useDispatch } from "react-redux";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";
import Tabs from "@material-ui/core/Tabs";
import { isComponentDisabled } from "@_utils/helpers";
import Tab from "@material-ui/core/Tab";
import { LicenseConstants } from "@_constants/license.constants";
import UploadLicensePopup from "../UploadLicensePopup/UploadLicensePopup";
import { caseConstants } from "@_constants/case.constants";
import { Tooltip } from "@material-ui/core";
import Scrollbar from "react-perfect-scrollbar-z";
import "scrollbar.css";

const CustomTabs = withStyles({
  root: {
    fontSize: 24,
    height: 60,
    backgroundColor: "var(--color-theme-white)",
  },
  indicator: {
    backgroundColor: "var(--color-theme-blue)",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    height: 60,
    fontSize: 18,
    color: "var(--color-theme-10)",
    "&:hover": {
      backgroundColor: "var(--color-theme-lightblue)",
      opacity: 1,
    },
    "&$selected": {
      color: "var(--color-theme-blue)",
    },
    "&:focus": {
      color: "var(--color-theme-blue)",
    },
  },
  selected: {},
}))(Tab);

const componetTag = "finding_popup";
function FindingsPopup(props) {
  const { store } = useContext(ReactReduxContext);
  const { imprintData, findingsData, caseId, overviewData, data } = props;
  const inputDisabled = isComponentDisabled(componetTag, data.state);
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({});
  const [findingInputs, setFindingInputs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedFinding, setSelectedFinding] = useState({});
  const [uploadPopup, setUploadPopup] = useState(false);

  useEffect(() => {
    setInputs({
      id: (imprintData && imprintData.id) || "",
      name: (imprintData && imprintData.name) || "",
      company: (imprintData && imprintData.company) || "",
      email: (imprintData && imprintData.email) || "",
      phone_number: (imprintData && imprintData.phone_number) || "",
      street_address: (imprintData && imprintData.street_address) || "",
      postal_code: (imprintData && imprintData.postal_code) || "",
      city: (imprintData && imprintData.city) || "",
      country: (imprintData && imprintData.country) || "",
    });
    setFindingInputs(findingsData);
  }, [imprintData, findingsData]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const closePopup = () => {
    setSelectedTab(0);
    props.close();
  };

  function deleteFinding(e, index) {
    dispatch(caseActions.deleteCaseFinding(caseId, findingsData[index].id));
    setTimeout(() => {
      dispatch(casesActions.getCases());
      setTimeout(() => {
        dispatch(caseActions.getCaseWithChargeDetails(caseId, overviewData));
        // TODO: snackbar and error handling before closing the popup
        setTimeout(() => {
          const currCase = store.getState().caseReducer.case[caseId];
          if (currCase.findings.length === 0) props.close();
        }, 150);
      }, 150);
    }, 150);
  }

  function handleFindingInputChange(e, index) {
    const { name, value } = e.target;
    let newFindings = [...findingInputs];
    newFindings[index].original_image[name] = value;
    setFindingInputs(newFindings);
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUploadPopup = (finding) => {
    if (!uploadPopup) setSelectedFinding(finding);
    setUploadPopup(!uploadPopup);
  };

  return (
    <DialogPopup
      title="Image uses"
      open={props.open}
      onClose={() => props.close()}
    >
      <div>
        <Scrollbar maxHeight="74vh">
          <div className={styles.tabs_container}>
            <CustomTabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="#EAEAEA"
              variant="fullWidth"
            >
              <CustomTab label="Image uses" />
              {imprintData && <CustomTab label="Website Owner" />}
            </CustomTabs>
          </div>
          <div className={styles.tabs_content_container}>
            <div hidden={selectedTab !== 0} className={styles.tab_content}>
              <div className={styles.add_finding_container}></div>

              {findingInputs &&
                findingInputs.map((finding, index) => (
                  <div key={index} className={styles.finding_container}>
                    <div className={styles.finding_images_container}>
                      <div className={styles.image_container}>
                        <img
                          className={styles.finding_image}
                          src={finding.original_image.original_image_url}
                          alt={""}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                        <a
                          className={styles.finding_link}
                          href={finding.original_image.original_image_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Original
                        </a>
                      </div>
                      <div className={styles.image_container}>
                        <img
                          className={styles.finding_image}
                          src={finding.page_screenshot_url}
                          alt={""}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                        <a
                          className={styles.finding_link}
                          href={finding.page_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Page Link
                        </a>
                      </div>
                    </div>
                    <div className={styles.image_name}>
                      <TextfieldInput
                        label="Name of Original Image"
                        name="name"
                        value={finding.original_image.filename}
                        onChange={(e) => handleFindingInputChange(e, index)}
                        disabled
                      />
                    </div>
                    {finding.license &&
                    finding.license.state === LicenseConstants.STATE_VALID ? (
                      <div className={styles.license_text}>
                        <p>License: Valid</p>
                      </div>
                    ) : finding.license &&
                      finding.license.state ===
                        LicenseConstants.STATE_INVALID ? (
                      <div className={styles.license_text}>
                        <p>License: Invalid</p>
                      </div>
                    ) : finding.license &&
                      finding.license.state ===
                        LicenseConstants.STATE_UNCHECKED ? (
                      <div className={styles.license_text}>
                        <p>License: Unchecked</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className={`action_container ${styles.finding_action_container}`}
                    >
                      {!inputDisabled && (
                        <>
                          <Tooltip
                            title={
                              inputDisabled ||
                              (findingsData.length === 1 &&
                                data.state === caseConstants.CASE_STATE_ACTIVE)
                                ? "Cannot delete the last finidng of an active case"
                                : ""
                            }
                            placement="top"
                            arrow
                          >
                            <span>
                              <Button
                                className={`btn_cancel ${
                                  inputDisabled ||
                                  (findingsData.length === 1 &&
                                    data.state ===
                                      caseConstants.CASE_STATE_ACTIVE)
                                    ? "btn_disabled"
                                    : ""
                                }`}
                                onClick={(e) => deleteFinding(e, index)}
                                disabled={
                                  inputDisabled ||
                                  (findingsData.length === 1 &&
                                    data.state ===
                                      caseConstants.CASE_STATE_ACTIVE)
                                }
                              >
                                Delete
                              </Button>
                            </span>
                          </Tooltip>
                          {data.state === caseConstants.CASE_STATE_ACTIVE ? (
                            <Button
                              className="btn_confirm"
                              onClick={() => handleUploadPopup(finding)}
                            >
                              Add License
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>

                    <UploadLicensePopup
                      open={uploadPopup}
                      close={handleUploadPopup}
                      findingId={selectedFinding.id}
                      caseId={caseId}
                      overviewData={overviewData}
                    />
                  </div>
                ))}
            </div>

            <div hidden={selectedTab !== 1} className={styles.tab_content}>
              <div className={styles.contact_container}>
                <TextfieldInput
                  className={styles.first_input}
                  label="Company Name"
                  name="company"
                  defaultValue={inputs.company}
                  onChange={handleInputChange}
                  disabled={inputDisabled}
                />
                <TextfieldInput
                  label="Name"
                  name="name"
                  defaultValue={inputs.name}
                  onChange={handleInputChange}
                  disabled={inputDisabled}
                />
              </div>
              <div className={styles.contact_container}>
                <TextfieldInput
                  className={styles.first_input}
                  label="Email"
                  name="email"
                  defaultValue={inputs.email}
                  onChange={handleInputChange}
                  disabled={inputDisabled}
                />
                <TextfieldInput
                  label="Phone"
                  name="phone_number"
                  defaultValue={inputs.phone_number}
                  onChange={handleInputChange}
                  disabled={inputDisabled}
                />
              </div>
              <div className={styles.contact_container}>
                <TextfieldInput
                  className={styles.first_input}
                  label="Street"
                  name="street_address"
                  defaultValue={inputs.street_address}
                  onChange={handleInputChange}
                  disabled={inputDisabled}
                />
                <TextfieldInput
                  label="Postal Code"
                  name="postal_code"
                  defaultValue={inputs.postal_code}
                  onChange={handleInputChange}
                  disabled={inputDisabled}
                />
              </div>
              <div className={styles.contact_container}>
                <TextfieldInput
                  className={styles.first_input}
                  label="City"
                  name="city"
                  defaultValue={inputs.city}
                  onChange={handleInputChange}
                  disabled={inputDisabled}
                />
                <TextfieldInput
                  label="Country"
                  name="country"
                  defaultValue={inputs.country}
                  onChange={handleInputChange}
                  disabled={inputDisabled}
                />
              </div>

              <hr className={styles.finding_hr} />

              <div className={styles.screenshot_section_container}>
                <div className={styles.imprint_image_container}>
                  <img
                    className={styles.imprint_image}
                    src={imprintData && imprintData.screenshot_url}
                    alt={""}
                  />
                </div>
                <div className={styles.imprint_text_container}>
                  <p>Screenshot of contact info</p>
                </div>
                <div className={styles.imprint_btn_container}></div>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>

      <div className="action_container">
        <Button className="btn_cancel" onClick={closePopup}>
          Close
        </Button>
      </div>
    </DialogPopup>
  );
}

export default FindingsPopup;
