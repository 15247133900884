import React from "react";
import SunEditor from "suneditor-react";
import "../../suneditor.min.css";
import plugins from "suneditor/src/plugins";
import { checkPlaceholders, newLinesToPTag } from "@_utils/helpers";
import CustomImagePlugin from "./customImagePlugin";


/**
 *
 * @param {object} props React Props
 * @param {number} props.height Sets height if present, default 400
 * @param {boolean} props.showAttachmentButton If true the attachment button is visible
 * @param {string} props.defaultValue Sets the initial content of the editor, default ""
 * @param {boolean} props.useReducedButtonList If true, the button list will not show list, indent, align, outdent+
 * @param {function} props.setAttachmentsCallback If attachmentButton is visible, this callback MUST be set
 * @returns
 */
function RichTextEditor(props) {
  const getAttachmentButton = () => {
    return {
      name: "addAttachementCommand",
      display: "command",

      title: "Add Attachment",
      buttonClass: "",
      // This icon uses Font Awesome
      innerHTML: `<svg viewBox="0 0 24 24" class="post-icon"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z" stroke-width="1"></path></svg>`,

      add: function (core, targetElement) {
        const context = core.context;
        context.addAttachementCommand = {
          targetButton: targetElement,
        };
      },
      active: function (element) {
        return true;
      },
      action: function () {
        props.addAttachmentCallback();
      },
    };
  };

  const defaultButtonList = [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["blockquote"],
    ["bold", "underline", "italic", "strike"],
    ["fontColor", "hiliteColor"],
    ["removeFormat"],
    ["outdent", "indent", "align"],
    ["list", "lineHeight"],
    ["link", "image", "horizontalRule"],
    props.showAttachmentButton !== false ? ["addAttachementCommand"] : [],
  ];

  const reducedButtonList = [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["blockquote"],
    ["bold", "underline", "italic", "strike"],
    ["fontColor", "hiliteColor"],
    ["removeFormat"],
    ["lineHeight"],
    ["link", "image", "horizontalRule"],
    props.showAttachmentButton !== false ? ["addAttachementCommand"] : [],
  ];

  return (
    <SunEditor
      getSunEditorInstance={(sunEditor) => {
        // add helper method
        var func = function (htmlContent, goToFirstLine) {
          try {
            this.insertHTML(htmlContent, true, false, false);
          } catch (e) {
            // clean
            this.setContents("");
            let parser = new DOMParser();
            let tempDoc = parser.parseFromString(htmlContent, "text/html");
            var all = tempDoc.getElementsByTagName("*");

            for (var i = 0, max = all.length; i < max; i++) {
              // Do something with the element here
              if (all[i].setAttribute && all[i].hasAttribute("style"))
                all[i].setAttribute(
                  "style",
                  all[i].getAttribute("style").toLocaleLowerCase()
                );
            }
            this.insertHTML(
              tempDoc.getElementsByTagName("body")[0].innerHTML,
              true,
              false,
              false
            );
          }
          if (this.getContents().startsWith("<p><br></p>") === true) {
            // remove the extra line break at the beginning. We can not avoid this when pure html is added
            // because a pseudo br element is added internally
            this.getContext().element.wysiwygFrame.childNodes[0].remove();
          }

          if (goToFirstLine === true) {
            this.core.setRange(
              this.getContext().element.wysiwyg.childNodes[0],
              0,
              this.getContext().element.wysiwyg.childNodes[0],
              0
            );
          }
        };

        var func2 = function () {
          return this.getContext().element.wysiwyg.innerHTML;
        };

        sunEditor["insertHtmlNoNewLine"] = func.bind(sunEditor);
        sunEditor["getHtmlUnleaned"] = func2.bind(sunEditor);

        // save instance for update content if needed
        props.reference.current = sunEditor;
        sunEditor.getContext().element.wysiwyg.setAttribute("tabindex", "4");
        //reset if still true
        if (window["skipNext"] === true) {
          // reset to accept the next blur
          window["skipNext"] = false;
        }
      }}
      // js source
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      // set initial value, highlight if there is already a missing placeholder
      defaultValue={props.defaultValue}
      //activate all plugins
      setAllPlugins={true}
      height="100%"
      // create toolbar
      setOptions={{
        plugins: {
          ...{ ...plugins, image: CustomImagePlugin },
          addAttachementCommand: getAttachmentButton(),
        },
        height:"100%",
        minHeight: "400px",
        showPathLabel: false,
        buttonList: props.useReducedButtonList
          ? reducedButtonList
          : defaultButtonList,

        font: [
          "Arial",
          "Verdana",
          "Tahoma",
          "Times New Roman",
          "Georgia",
          "Garamond",
          "Courier New",
          "Brush Script MT",
        ],
      }}
      
      onLoad={(core, reload) => {
        props.reference.current
          .getContext()
          .element.wysiwyg.setAttribute("tabindex", "4");
      }}
      onKeyDown={(ev, core) => {
        if (ev.key === "Enter") {
          if (
            core.getSelection() &&
            core.getSelection().focusNode &&
            core.getSelection().focusNode.scrollIntoViewIfNeeded
          )
            core.getSelection().focusNode.scrollIntoViewIfNeeded();
        }
      }}
      onDrop={(e, cleanData, maxCharCount, core) => {
        if (e.dataTransfer.files[0])
          if (e.dataTransfer.files[0].type.startsWith("image/")) {
            props.reference.current.insertImage(e.dataTransfer.files);
            return "";
          } else {
            for (const file of e.dataTransfer.files) {
              const reader = new window.FileReader();
              reader.readAsArrayBuffer(file);

              /* 
            lastModified:1539873569000
            lastModifiedDate:Thu Oct 18 2018 16:39:29 GMT+0200 (Mitteleuropäische Sommerzeit) {}
            name:"ip_command.pdf"
            size:89608
            type: "application/pdf"
            webkitRelativePath:""
            */
              reader.onloadend = () => {
                props.setAttachmentsCallback({
                  filename: file.name,
                  content: Buffer(reader.result).toString("base64"),
                  contentType: file.type,
                  encoding: "base64",
                });
              };
            }
          }
      }}
      onPaste={(e, cleanData, maxCharCount) => {
        // if image is pasted, add it as base 64
        if (e.clipboardData.files[0]) {
          if (e.clipboardData.files[0].type.startsWith("image/")) {
            props.reference.current.insertImage(e.clipboardData.files);
            return "";
          } else {
            const file = e.clipboardData.files[0];
            const reader = new window.FileReader();
            reader.readAsArrayBuffer(file);

            /* 
              lastModified:1539873569000
              lastModifiedDate:Thu Oct 18 2018 16:39:29 GMT+0200 (Mitteleuropäische Sommerzeit) {}
              name:"ip_command.pdf"
              size:89608
              type: "application/pdf"
              webkitRelativePath:""
              */
            reader.onloadend = () => {
              props.setAttachmentsCallback({
                filename: file.name,
                content: Buffer(reader.result).toString("base64"),
                contentType: file.type,
                encoding: "base64",
              });
            };
          }
        }

        if (e.clipboardData.types.length >= 2) {
          // seems we have txt and html
          // lets search for html
          for (const item of e.clipboardData.items) {
            if (item.type === "text/html") {
              props.reference.current.insertHTML(
                e.clipboardData
                  .getData("text/html")
                  .replace(/<meta /g, "<")
                  .replace(/<http-equiv=content-type /g, "<")
                  .replace(/<name /g, "<"),
                true,
                false
              );

              return false;
            }
          }
        }

        if (e.clipboardData.types[0] === "text/html") {
          props.reference.current.insertHTML(
            e.clipboardData
              .getData("text/html")
              .replace(/<meta /g, "<")
              .replace(/<http-equiv=content-type /g, "<")
              .replace(/<name /g, "<"),
            true,
            false
          );

          return false;
        }

        if (e.clipboardData.types[0] === "text/plain") {
          props.reference.current.insertHTML(
            newLinesToPTag(e.clipboardData.getData("text/plain")),
            true,
            false,
            false
          );
          return false;
        }
      }}
      onChange={(data) => {
        // check if change is text and update missing placeholder warning
        if (props.setMissingPlaceholderCallback) {
          if (!data || data.length === 0 || typeof data !== "string") return;
          props.setMissingPlaceholderCallback(checkPlaceholders(data));
        }
      }}
      onClick={(e) => {
        if (props.onClickCallback) props.onClickCallback(e);
      }}
      onBlur={(e, data) => {
        if (!props.setMissingPlaceholderCallback)
          if (props.setContentCallback) {
            props.setContentCallback(e.target.innerHTML);
            return;
          }
        if (!e.relatedTarget) return;
        //check if we had to skip the next blur
        if (window["skipNext"] === true) {
          // reset to accept the next blur
          window["skipNext"] = false;
          return;
        }
        // update conten with missing placeholder
        // this will focus the editor again so that
        // the focusOut has no effect
        else if (props.setContentCallback)
          props.setContentCallback(e.target.innerHTML);

        // because we had focus the editor again
        // we must create focus event for the related target
        // but we have to skip the next blur event
        // because it will end in infinite loop if we do not
        window["skipNext"] = true;

        // focus the real target again because
        // setContent had focus the editor again
        e.relatedTarget.focus();
      }}
    />
  );
}

export default RichTextEditor;
